<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="man-title">
        <span>漏检重置记录</span>
        <el-button @click="add" class="btn" type="primary" size="mini" :disabled="roleId == 4">重置</el-button>
      </div>
      <div class="table-list">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-form style="text-align:left;" label-position="left" inline class="demo-table-expand">
                <el-form-item label="" class="task-info" v-for="(item,index) in props.row.taskInfos" :key="index">
                  <span class="task-info-title">{{ item.taskCode?item.taskCode:'(空)' }}</span>
                  <span class="patrol-info" v-for="(pItem,pIndex) in item.patrolInfos" :key="pIndex">
                    <span class="patrol-item">{{ pItem.patrolName?pItem.patrolName:'(空)' }}</span>
                  </span>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="operatorName" label="操作人"></el-table-column>
          <el-table-column prop="modifyRemarks" label="漏检原因"></el-table-column>
          <el-table-column prop="taskCreateTime" label="任务创建时间" width="160">
            <template slot-scope="scope">
              {{ scope.row.taskCreateTime ? dayjs(scope.row.taskCreateTime).format('YYYY-MM-DD'):'' }}
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="重置时间" width="160">
            <template slot-scope="scope">
              {{ scope.row.createTime ? dayjs(scope.row.createTime).format('YYYY-MM-DD HH:mm:ss'):'' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-dropdown placement="bottom-start" trigger="click">
                <span @click.stop="">
                  <span class="el-dropdown-link">
                    操作<i class="el-icon-arrow-down"></i>
                  </span>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item><div @click="details(scope.row)">详情</div></el-dropdown-item>
                  <!-- <el-dropdown-item :disabled="roleId == 4"><div @click="edit(scope.row)">编辑</div></el-dropdown-item>
                  <el-dropdown-item :disabled="roleId == 4"><div @click="dlt(scope.row)">删除</div></el-dropdown-item> -->
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page>
      </div>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs';
export default {
  components: {
    page
  },
  data() {
    return {
      loading: false,
      size: 50,
      page: 1,
      total: 0,
      formInline: {},
      tableData: [],
      roleId: 0
    };
  },
  methods: {
    dayjs,
    edit(row) {
      this.$router.push({ name: 'deviceListEdit', params: {
        row: row,
        bumen: this.bumen,
        leixin: this.leixin,
        yuangong: this.yuangong
      }})
    },
    details(row) {
      // this.$router.push({ name: 'deviceListDetail', params: {row: row}})
    },
    dlt(row) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax.post('deviceDelete', {
          id: row.id
        }).then(res => {
          this.loadListData()
        })
      })
    },
    add() {
      this.$router.push('/cycleReset')
    },
    loadListData() {
      this.loading = true
      this.$ajax.post('queryModiyLogs', {
        pageNum: this.page,
        pageSize: this.size,
      }).then(res => {
        this.tableData = res.data
        this.total = res.total
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadListData()
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentSize', val)
      this.loadListData()
    },
    getLastPage() {
      this.page = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.size = Number(sessionStorage.getItem('currentSize')) || 50
    },
  },
  mounted() {
    this.getLastPage()
    this.getSize()
    this.loadListData()
    this.roleId = sessionStorage.getItem('r')
  }
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.btn {
  float: right;
}
.search {
  padding: 0 10px;
}
.task-info {
  background-color: #f8f8f8;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
}
.task-info-title {
  background: #e7f3ff;
  display: block;
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  line-height: 40px;
  color: #409eff;
  padding: 0 20px;
}
/deep/.el-form--inline .el-form-item__content {
  display: block;
}
.patrol-info {
  padding-left: 10px;
  padding-right: 5px;
}
.patrol-info::after {
  content: '';
  display: inline-block;
  width: 1px;
  height: 10px;
  background: #c8c8c8;
  margin-left: 20px;
}
.patrol-info:nth-last-child(1)::after {
  display: none;
}
.patrol-item {
  line-height: 40px;
  padding: 5px 10px;
  box-sizing: border-box;
  white-space: nowrap;
  color: #797979;
}
.patrol-item::after {
  content: '';
  display: inline-block;
  width: 1px;
  height: 10px;
  background: #c8c8c8;
  margin-left: 20px;
}
.patrol-item:nth-last-child(1)::after {
  display: none;
}
</style>